<template>
  <footer class="ana-footer">
    <div class="container">
      <div
        class="col-lg-10 px-0 py-3 mx-auto d-block d-lg-flex align-items-center justify-content-between ana-footer__content"
      >
        <div class="my-2">
          <p class="small text-muted">
            &copy; {{ currentYear }} Ana App. All rights reserved.
          </p>
        </div>
        <div class="small d-flex align-items-center my-2">
          <a
            href="https://ogurinkabenjamin.com/ana_app/privacy"
            target="_blank"
            class="text-muted d-inline-block mr-1"
            >Privacy Policy</a
          >
          <a
            href="https://ogurinkabenjamin.com/ana_app/terms"
            target="_blank"
            class="text-muted d-inline-block mr-1"
            >Terms and Conditions</a
          >
          <a
            href="https://ogurinkabenjamin.com/pages/contact"
            target="_blank"
            class="text-muted d-inline-block mr-1"
            >Contact</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    currentYear() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      return currentYear;
    },
  },
};
</script>

<style>
.ana-footer {
  min-height: 100px;
  display: flex;
  align-items: flex-end;
}
.ana-footer .ana-footer__content {
  border-top: 1px solid var(--anaGrey2);
}
</style>
