<template>
  <div
    class="small"
    :class="{
      'text-danger': alertType == 'error',
      'text-success': alertType == 'success',
    }"
  >
    {{ alertMessage }}
  </div>
</template>

<script>
export default {
  name: "InlineAlert",
  props: {
    alertMessage: {
      type: String,
      default: "",
    },
    alertType: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
