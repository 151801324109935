import Vue from "vue";
import JQuery from "jquery";
import VueMeta from "vue-meta";
import VueSweetalert2 from "vue-sweetalert2";
import Toast from "vue-toastification";
import VTooltip from "v-tooltip";
import VueSocialSharing from "vue-social-sharing";
import vSelect from "vue-select";
import SpinnerLoader from "@/components/SpinnerLoader";
import LinearLoader from "@/components/LinearLoader";
import InlineAlert from "@/components/InlineAlert";
import Vuelidate from "vuelidate";
Vue.use(VueSocialSharing);
Vue.use(Toast, {
  position: "top-center",
  timeout: 10000,
  icon: true,
  transition: "Vue-Toastification__fade",
});
Vue.use(VTooltip);
Vue.use(Vuelidate);
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};
Vue.component("v-select", vSelect);
Vue.component("SpinnerLoader", SpinnerLoader);
Vue.component("LinearLoader", LinearLoader);
Vue.component("InlineAlert", InlineAlert);
Vue.use(VueSweetalert2, options);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
window.$ = JQuery;
