<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
export default {
  name: "App",
  components: {
    Footer,
  },
  mounted() {
    if (this.AppTheme == "dark_theme") {
      document.body.classList.add("dark_theme");
    } else {
      document.body.classList.add("light_theme");
    }
  },
  watch: {
    $route(to, from) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
