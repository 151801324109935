const state = {
  theme: getSystemTheme(),
};

const getters = {
  theme: (state) => state.theme,
};

const mutations = {
  setTheme(state, theme) {
    state.theme = theme;
    applyThemeToBody(theme);
  },
};

const actions = {
  toggleTheme({ commit, state }, theme) {
    commit("setTheme", theme);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

// Helper function to get the user's system theme
function getSystemTheme() {
  const prefersDarkTheme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  return prefersDarkTheme ? "dark_theme" : "light_theme";
}
function applyThemeToBody(theme) {
  document.body.classList.toggle("light_theme", theme === "light_theme");
  document.body.classList.toggle("dark_theme", theme === "dark_theme");
}
