import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Ana App",
      metaTags: [
        {
          name: "description",
          content: "Easy and minimal way to create links you can monitor",
        },
        {
          property: "og:description",
          content: "Easy and minimal way to create links you can monitor",
        },
      ],
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/dash/Overview.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.state.isUserLoggedIn) {
        next({
          name: "Login",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/SignUp",
    name: "SignUp",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/dash/Overview",
    component: () => import("../views/dash/Index.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.state.isUserLoggedIn) {
        next({
          name: "Login",
        });
      } else {
        next();
      }
    },
    children: [
      {
        path: "/dash/Overview",
        name: "Overview",
        component: () => import("../views/dash/Overview.vue"),
      },
      {
        path: "/dash/YourAccount",
        name: "Profile",
        component: () => import("../views/dash/YourAccount/Index.vue"),
      },
      {
        path: "/dash/YourAccount/Usage",
        name: "AccountUsage",
        component: () => import("../views/dash/YourAccount/Usage.vue"),
      },
      {
        path: "/dash/LinkGroups",
        name: "LinkGroups",
        component: () => import("../views/dash/LinkGroups.vue"),
      },
      {
        path: "/dash/API",
        name: "API",
        component: () => import("../views/dash/API.vue"),
      },
      {
        path: "/dash/YourTeam",
        name: "YourTeam",
        component: () => import("../views/dash/YourTeam/Index.vue"),
      },
      {
        path: "/dash/YourLinks/Search",
        name: "Search",
        component: () => import("../views/dash/YourLinks/search.vue"),
      },
      {
        path: "/dash/Notifications",
        name: "Notifications",
        component: () => import("../views/dash/Notif.vue"),
      },
      {
        path: "/dash/YourLinks",
        component: () => import("../views/dash/YourLinks/index.vue"),
        children: [
          {
            path: "/dash/YourLinks",
            name: "Links",
            component: () => import("../views/dash/YourLinks/list.vue"),
          },
          {
            path: "/dash/YourLinks/Favourites",
            name: "FavouriteLinks",
            component: () => import("../views/dash/YourLinks/favourites.vue"),
          },
        ],
      },
      {
        path: "/dash/YourLinks/Create",
        name: "CreateLink",
        component: () => import("../views/dash/YourLinks/create.vue"),
      },
      {
        path: "/dash/link/:id/:slug",
        name: "SingleLink",
        component: () => import("../views/dash/link/_id.vue"),
      },
      {
        path: "/dash/link/edit/:id/:slug",
        name: "EditLink",
        component: () => import("../views/dash/link/edit/_id.vue"),
      },
      {
        path: "/dash/link/redirect/:id/:slug",
        name: "RedirectLink",
        component: () => import("../views/dash/link/redirect/_id.vue"),
      },
    ],
  },

  {
    path: "/account/verify/:token",
    name: "VerifyUser",
    component: () => import("../views/account/verify/_token.vue"),
  },
  {
    path: "/account/passwordReset/:token",
    name: "ResetPassword",
    component: () => import("../views/account/passwordReset/_token.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
