<template>
  <div class="linear-loader"></div>
</template>

<script>
export default {
  name: "LinearLoader",
};
</script>

<style></style>
