import axios from "axios";
import store from "@/store";
import router from "@/router";
if (window.location.href.indexOf("localhost") > -1) {
  axios.defaults.baseURL = "http://localhost:8888/v1/";
} else {
  axios.defaults.baseURL = "https://anaserver.ben.com.ng/v1/";
}

axios.defaults.timeout === 1000;
axios.interceptors.request.use(
  (config) => {
    const token = store.state.token;
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await axios.post("auth/refresh", {
            refreshToken: store.state.refreshToken,
          });
          const { token, refreshToken } = rs.data;
          store.dispatch("setToken", token);
          store.dispatch("setRefToken", refreshToken);
          err.config.headers["Authorization"] = `Bearer ${token}`;
          return new Promise((resolve, reject) => {
            axios
              .request(originalConfig)
              .then((response) => {
                resolve(response);
              })
              .catch((err) => {
                router.push("/");
                console.log(err);
                reject(err);
              });
          });
        } catch (_error) {
          console.log(_error);
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
