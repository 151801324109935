<template>
  <div>
    <div class="auth-wrapper">
      <div class="mt-5 text-center container">
        <h3 class="text-center ana_h3 ana_text-grey">You are not logged in</h3>
        <p class="text-center ana_paragraph ana_text-grey">
          Start creating powerful links with Ana
        </p>
        <div
          class="my-5 d-block d-md-flex align-items-center justify-content-center"
        >
          <router-link to="/login" class="ana-btn ana-btn__outline m-1">
            I have an account</router-link
          >
          <router-link to="/SignUp" class="ana-btn ana-btn__fill m-1">
            Create new account</router-link
          >
        </div>
        <div
          class="col-lg-6 mx-auto my-5 d-flex align-items-center justify-content-center"
        >
          <img
            src="../assets/img/app/not_logged_in.svg"
            class="auth-img"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  metaInfo: {
    title: "Unauthorized - Ana app",
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  // mounted() {
  //   if (this.isUserLoggedIn) {
  //     this.$router.push('/dash/Overview')
  //   }
  // }
};
</script>
